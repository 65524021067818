import Vue from 'vue'
import Router from 'vue-router'
import qs from 'qs'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard/Dashboard')

// Views - Orders
const Orders = () => import('@/views/orders/table')
const EditOrder = () => import('@/views/orders/edit')

const ordersSeo = () => import('@/views/other/OrdersSeo')
//Views - Tasks

const Tasks = () => import('@/views/tasks/Table')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

//Views - Regions
const Countries = () => import('@/views/countries/Countries')
const EditCountry = () => import('@/views/countries/EditCountry')
const EditCity = () => import('@/views/countries/EditCity')


//Views - Cleaners
const Cleaners = () => import('@/views/cleaners/table')
const CleanersCalendar = () => import('@/views/cleaners/Calendar')
const CleanersFunnel = () => import('@/views/cleaners/Funnel')
const CleanersFunnelAdaptation = () => import('@/views/cleaners/FunnelAdaptation')
const NewClientsFunnel = () => import('@/views/clients/NewClientsFunnel')
const CleanerMentor = () => import('@/views/cleanerMentor/table')
const CleanersStatistics = () => import('@/views/cleaners/SalaryStats')

//Views - Clients
const Clients = () => import('@/views/clients/Clients')

//Views - Leads
const Leads = () => import('@/views/leads/Leads')
const Leads2 = () => import('@/views/leads/Leads2')
const LeadsNew = () => import('@/views/leads/LeadsNew')

//Views - Calls
const Calls = () => import('@/views/Calls/Table')

//Views - Feedback
const Feedback = () => import('@/views/feedback/Table')

//Views - users
const Users = () => import('@/views/users/Users')

//Views - promos
const Promos = () => import('@/views/promo/Promos')
const Promos2 = () => import('@/views/promo/Promos2')

//Views - statistics
const Salary = () => import('@/views/statistics/Salary')
const StatsManagers = () => import('@/views/statistics/Managers')
const Regions = () => import('@/views/statistics/Regions')
const RegionsAdv = () => import('@/views/statistics/RegionsAdv')
const RegionsDashboard = () => import('@/views/statistics/RegionsDashboard')
const RegionsCharts = () => import('@/views/statistics/RegionsCharts')
const Features = () => import('@/views/other/Features')
const SMS = () => import('@/views/statistics/Sms')

//Views Blogs
const BlogTable = () => import('@/views/blog/Table');
const Blog = () => import('@/views/blog/Item');

//Views - other
const Balance = () => import('@/views/other/balance/Table')
const Stock = () => import('@/views/other/stock/Table')
const BalanceRegions = () => import('@/views/statistics/balanceRegions/Table')
const Koefs = () => import('@/views/other/koefs/Koefs')
const PartnerPromo = () => import('@/views/other/partnerPromos/Promos')
const PromosList = () => import('@/views/other/partnerPromos/PromosList')
const ShortLinks = () => import('@/views/other/ShortLinks')
Vue.use(Router)
import {Server} from '@/api/server'
import Translation from "../views/other/translation/Translation";
import RegionsInstruments from "../views/statistics/RegionsInstruments";
import OrdersDay from "../views/orders/OrdersDay";
import RecordsPage from "@/views/other/records/RecordsPage";
import CleanerPage from "@/views/cleaners/CleanerPage";
import ManagerSalaryCalculator from "@/views/statistics/ManagerSalaryCalculator";
import PushList from "@/views/clients/push/PushList";
import PushPage from "@/views/clients/push/PushPage";
import Metabase from "../views/other/Metabase.vue";

let server = new Server;

const ifNotAuthenticated = (to, from, next) => {
  if (!server.hasLoggedIn()) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (server.hasLoggedIn()) {
    next()
    return
  }
  next('/pages/login')
}

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => {
    return {x: 0, y: 0}
  },
  parseQuery: (query) => {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    let result = qs.stringify(query, {encode: false});

    return result ? ('?' + result) : '';
  },
  beforeEach: (to, from, next) => {
    window.scrollTo(0, 0)
  },
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Главная',
      component: TheContainer,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'dashboard',
          name: 'Дашборд',
          component: Dashboard
        },
        {
          path: 'orders',
          name: 'Заказы',
          component: Orders,
        },
        {
          path: 'ordersSeo',
          name: 'Аналитика',
          component: ordersSeo,
        },
        {
          path: 'orders/:order_id',
          name: 'Редактирование',
          component: EditOrder,
        },
        {
          path: 'create/:phone?',
          name: 'Создание',
          component: EditOrder,
        },
        {
          path: 'tasks',
          name: 'Задачи',
          component: Tasks,
        },
        {
          path: '/countries',
          redirect: '/countries/',
          name: 'Настройки',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: Countries,
            },
            {
              path: ':country_id',
              name: 'Редактирование страны',
              component: EditCountry,

            },
            {
              path: ':country_id/:city_id',
              name: 'Редактирование города',
              component: EditCity,
            },
          ]
        },
        {
          path: '/cleaners/list',
          name: 'Клинеры',
          component: Cleaners,
        },
        {
          path: '/cleaners/list/:id',
          name: 'Клинер',
          component: CleanerPage,
        },
        {
          path: '/cleaners/calendar',
          name: 'Календарь клинеров',
          component: CleanersCalendar,
        },
        {
          path: '/cleaners/funnel',
          name: 'Воронка клинеров',
          component: CleanersFunnel,
        },
        {
          path: '/cleaners/funnel_adaptation',
          name: 'Воронка клинеров',
          component: CleanersFunnelAdaptation,
        },
        {
          path: '/cleaners/salaryStats',
          name: 'Cтатистика клинеров',
          component: CleanersStatistics,
        },
        {
          path: '/clients',
          name: 'Клиенты',
          component: Clients,
        },
        {
          path: '/new-clients-funnel',
          name: 'Новые клиенты воронка',
          component: NewClientsFunnel,
        },
        {
          path: '/mailingLists',
          name: 'Рассылки',
          component: PushList,
        },
        {
          path: '/mailingLists/:id',
          name: 'Рассылка',
          component: PushPage,
        },
        {
          path: '/leads',
          name: 'Лиды',
          component: Leads,
        },
        {
          path: '/leads2',
          name: 'Лиды',
          component: Leads2,
        },
        {
          path: '/leads3',
          name: 'Лиды',
          component: LeadsNew,
        },
        {
          path: '/feedback',
          name: 'Качество',
          component: Feedback,
        },
        {
          path: '/calls',
          name: 'История звонков',
          component: Calls,
        },
        {
          path: '/users',
          name: 'Менеджеры',
          component: Users,
        },
        {
          path: '/translation',
          name: 'Перевод',
          component: Translation,
        },
        {
          path: '/promos',
          name: 'Промокоды',
          component: Promos,
        },
        {
          path: '/promos2',
          name: 'Промокоды (NEW)',
          component: Promos2,
        },
        {
          path: '/metabase',
          name: 'Metabase',
          component: Metabase,
        },
        {
          path: '/statistics/salary',
          name: 'ЗП Клинеров',
          component: Salary,
        },
        {
          path: '/statistics/managers',
          name: 'Менеджеры',
          component: StatsManagers,
        },
        {
          path: '/statistics/salary_calculator',
          name: 'Калькулятор зарплаты',
          component: ManagerSalaryCalculator,
        },
        {
          path: '/regions/revenue',
          name: 'Регионы',
          component: Regions,
        },
        {
          path: '/regions/records',
          name: 'Рекорды',
          component: RecordsPage,
        },
        {
          path: '/regions/adv',
          name: 'Реклама',
          component: RegionsAdv,
        },
        {
          path: '/regions/dashboard',
          name: 'Дашборд',
          component: RegionsDashboard,
        }, {
          path: '/regions/charts',
          name: 'Графики',
          component: RegionsCharts,
        },
        {
          path: '/regions/features',
          name: 'Функции',
          component: Features,
        },
        {
          path: '/day_orders',
          name: 'Заказы на день',
          component: OrdersDay,
        }, {
          path: '/regions/instruments',
          name: 'Инструменты',
          component: RegionsInstruments,
        },
        {
          path: '/regions/balance',
          name: 'Финансы регионы',
          component: BalanceRegions,
        },
        {
          path: '/statistics/sms',
          name: 'СМС',
          component: SMS,
        },
        {
          path: '/other/koefs',
          name: 'Коэффициенты',
          component: Koefs,
        },
        {
          path: '/other/balance',
          name: 'Финансы',
          component: Balance,
        },
        {
          path: '/other/stock',
          name: 'Склад',
          component: Stock,
        },
        {
          path: '/other/partner_promos',
          name: 'Партнерские промо',
          redirect: '/other/partner_promos/',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              component: PartnerPromo,
            },
            {
              path: ':id',
              name: 'Список промо',
              component: PromosList,
            }
          ]
        },
        {
          path: '/cleanerMentor',
          name: 'Стажёры',
          component: CleanerMentor,
        },
        {
          path: '/other/shortlink',
          name: 'Короткие ссылки',
          component: ShortLinks,
        },
        {
          path: '/blog',
          name: 'Статьи',
          component: BlogTable,
        },
        {
          path: '/blog/:id',
          name: 'Статья',
          component: Blog,
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Страницы',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'login',
          name: 'Авторизация',
          component: Login,
          beforeEnter: ifNotAuthenticated,
        }
      ]
    },
  ]
}

