<template>
  <v-app>
    <v-container>
      <iframe
          src="https://metabase.cleanbros.ru/public/dashboard/e5c6e52f-5392-48f6-9301-73853111ad4c"
          frameborder="0"
          width="100%"
          height="100%"
          allowtransparency
      ></iframe>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "Metabase",
}
</script>
